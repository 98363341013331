import { getInitBookPayment } from "@lib/api/membershipUpgrade";
import { removeCacheUserInfo } from "@lib/api/profile/removeCacheUserInfo";
import { planInitbook } from "@lib/api/renewPlans/planInitBook";
import { getPlanProceedBook } from "@lib/api/renewPlans/planProceedBook";
import { getSubscriptionPlan } from "@lib/upgradeMember/getSubscriptionPlans";
import { getUpgradeMembershipProceedBook } from "@lib/upgradeMember/proceedBook";
import { getUpgradeMembershipRenewPlan } from "@lib/upgradeMember/renewPlan";
import { getCookie } from "cookies-next";

const getDateFormat = (value: string) => {
  const date = value.split("-");
  return new Date(`${date[1]}/${date[2]}/${date[0]}`);
};

const checkElement = (data: any) => {
  if (data === null || data === undefined || data === "") {
    return false;
  } else {
    return true;
  }
};

const checkPlan = async (
  userInfo: CheckLoginResultesponseType,
  showPlansModal: (flag: boolean) => void,
  setAvailablePlans: (plans: PlansApiDataType[] | null) => void,
  currentUser?: UserModelType
) => {
  const referer = window.location.href.split("&orderid")[0];
  const subscriptionDetails = userInfo.subscription_details.find((f) => f);
  const statusPlan = subscriptionDetails?.plan_status;
  const planExpiryDate = subscriptionDetails?.plan_expiry_date;
  // const statusPlan = userInfo.subscription_details[0].plan_status;

  // if user doesnt have a subscription plan assigned
  if (!subscriptionDetails) {
    const availableSubs = await getSubscriptionPlan(userInfo.token);

    setAvailablePlans(availableSubs?.data ?? null);

    // Get Available Plans
    const updatePlanZero =
      availableSubs &&
      Array.isArray(availableSubs?.data) &&
      availableSubs?.data.length > 0;

    if (updatePlanZero) {
      const plan = availableSubs?.data?.find(
        (obj) => Number(obj?.membership_plan_initial_price) === 0
      );

      // if exist a plan zero in available plans follow this flow - initbook - proceed
      if (plan?.membership_plan_id) {
        let data1 = {
          sub_plan_id: plan?.membership_plan_id,
          currency: plan?.membershipplan_currency,
          subscription_code: plan?.membership_plan_code,
          membership_id: Number(plan?.membership_id),
          plan_amount: plan?.membership_plan_renewal_price,
          userId: currentUser?.userId,
          referer: referer,
          charge_org: false,
        };

        const res = await getInitBookPayment(data1);
        if (res) {
          const proceedResponse = await getUpgradeMembershipProceedBook({
            booking_id: res.result.booking_id,
            lang_code: String(getCookie("lang_code") || "en-US"),
            hash: currentUser?.userId ?? "",
            charge_org: false,
          });

          if (proceedResponse) {
            removeCacheUserInfo(userInfo.userId ?? 0);
            return "ACTIVE";
          }
        }
      } else {
        // // if not exist a plan zero in available plans follow this flow - open modal to show availabel plans
        // showPlansModal(true);
        if (
          availableSubs.data.length == 1 &&
          availableSubs.data[0].init_charge_org
        ) {
          let availableChargeOrg = availableSubs?.data[0];
          let initbookData: InitBookSubsPlanElementsType = {
            is_renewal: false, // i send false because in this situation client doesnt have plan applied
            currency: String(availableChargeOrg?.membershipplan_currency),
            plan_amount: String(
              availableChargeOrg?.membership_plan_initial_price
            ),
            id: Number(availableChargeOrg?.membership_plan_id),
            subscription_code: String(availableChargeOrg?.membership_plan_code),
            lang_code: String(getCookie("lang_code") || "en-US"),
            user_type: 2,
            quot_id: "",
            // pg_token: String(cardPaymentReq?.pg_token),
            // pg_type: String(cardPaymentReq?.pg_type),
            is_trial: availableChargeOrg?.plan_charge_after == 1,
            charge_org: availableChargeOrg?.init_charge_org,
          };
          console.log(
            "availableSubs initbookData",
            initbookData,
            availableSubs
          );

          const resInit = await planInitbook(initbookData);
          if (resInit?.data?.booking_id) {
            getPlanProceedBook({
              booking_id: String(resInit?.data?.booking_id),
              language_code: String(getCookie("lang_code") || "en-US"),
              is_oxxo: "",
              is_trial: availableChargeOrg.plan_charge_after == 1,
              charge_org: availableChargeOrg?.init_charge_org,
            }).then((res) => {
              if (res) {
                removeCacheUserInfo(userInfo.userId ?? 0);
                return "ACTIVE-REFRESH";
              }
            });
          }
          return "ACTIVE-REFRESH";
        } else {
          // if not exist a plan zero in available plans follow this flow - open modal to show availabel plans
          showPlansModal(true);
        }
      }
    }
  } else {
    if (subscriptionDetails && statusPlan && planExpiryDate) {
      const datePlanExpiry = planExpiryDate.split("T")[0];
      const date = getDateFormat(datePlanExpiry);
      const dateValidate = date < new Date();

      if (statusPlan === "EXPIRED" || dateValidate) {
        // const result = await getDatasubscriptionplanssType();
        const result = await getSubscriptionPlan(userInfo.token);
        if (result && Array.isArray(result?.data)) {
          const results = result?.data?.find(
            (obj) => Number(obj?.membership_plan_renewal_price) === 0
          );
          if (results !== undefined) {
            let data1 = {
              sub_plan_id: results?.membership_plan_id,
              currency: results?.membershipplan_currency,
              subscription_code: results?.membership_plan_code,
              membership_id: Number(results?.membership_id),
              plan_amount: results?.membership_plan_renewal_price,
              userId: currentUser?.userId,
              referer: referer,
              charge_org: false,
            };

            const res = await getInitBookPayment(data1);

            try {
              const proceedResponse = await getUpgradeMembershipProceedBook({
                booking_id: res.result.booking_id,
                lang_code: String(getCookie("lang_code") || "en-US"),
                hash: currentUser?.userId ?? "",
                charge_org: false,
              });

              if (
                checkElement(proceedResponse?.result?.subscription_info) &&
                proceedResponse?.result?.subscription_info &&
                Array.isArray(proceedResponse?.result?.subscription_info) &&
                proceedResponse?.result?.subscription_info?.length > 0
              ) {
                if (
                  !["Payment_Failed", "Failed"].includes(
                    proceedResponse?.result?.booking_status || ""
                  )
                ) {
                  const renewResp = await getUpgradeMembershipRenewPlan({
                    is_autorenewal: results?.is_autorenewable || 0,
                    subs_plan_id: results?.membership_plan_id || 0,
                  });

                  if (renewResp?.data?.data) {
                    removeCacheUserInfo(userInfo.userId ?? 0);

                    return "ACTIVE";
                  }
                } else {
                  return statusPlan;
                }
              } else {
                return statusPlan;
              }
            } catch (err) {
              console.log(err);
            }
          } else {
            return statusPlan;
          }
        }
      } else {
        return statusPlan;
      }
    }
  }
};

const PlanService = {
  checkPlan,
};

export default PlanService;

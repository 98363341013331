import styled from "styled-components";
import { Modal, Col, Row } from "react-bootstrap";

export const ModalCustom = styled(Modal)`
  background: ${({ theme }: any) =>
    theme.isMobile ? "rgba(255,255, 255, 1)" : "rgba(64, 67, 120, 0.75)"};
  & .modal-content {
    border-radius: ${({ theme }: any) => (theme.isMobile ? "0px" : "30px")};
  }
  & .modal-dialog {
    max-width: 29rem;
    min-width: ${({ theme }: any) => (theme.isMobile ? "10px" : "500px")};
  }
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    & .modal-dialog {
      min-width: 100%;
      height: auto;
      min-height: 100%;
    }
  }
`;

export const RowCloseModalSignUp = styled(Row)`
  justify-content: ${({ theme }: any) =>
    theme.bodyPassword ? "space-between" : "right"};
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: -3rem;
`;

export const SpanCloseModalSignUp = styled.span`
  position: relative;
  width: 2.5rem;
  cursor: pointer;
`;

export const ImgCloseModalSignUp = styled.img`
  content: url("/images/modalSignInSignUp/CloseBlue.svg");
  width: 1rem;
`;

export const DivModalHeader = styled.div`
  padding-left: 3rem;
  padding-right: 3rem;
`;
export const ModalHeader = styled(Modal.Header)`
  margin-top: 2rem;
`;
export const RowIconSignUp = styled(Row)`
  width: 100%;
  text-align: center;
  --bs-gutter-x: 0rem;
`;
export const SpanIconSignUp = styled.span`
  content: url("/images/modalSignInSignUp/SignUp.svg");
  width: 3.5rem;
  height: 3.5rem;
`;
export const ColIconSignUp = styled(Col)`
  width: 100%;
  font-size: 21px;
  color: grey;
  //border-bottom: 1px solid #ddd;
`;

export const ColIconSignUpMode = styled(Col)`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }: any) =>
    theme.type == "MainTittle" ? "17px" : "12px"};
  font-weight: ${({ theme }: any) =>
    theme.type == "MainTittle" ? "700" : "300"};
  color: grey;
  margin-top: ${({ theme }: any) =>
    theme.type == "MainTittle" ? "0.5rem" : "0.5rem"};
  //border-bottom: 1px solid #ddd;
`;

export const DivModalBody = styled.div`
  padding-left: 3rem;
  padding-right: 3rem;
`;

export const ModalBody = styled(Modal.Body)`
  display: ${({ theme }: any) =>
    theme.bodyPassword ? "none" : theme.showMailRegister ? "none" : ""};

  ${({ theme }: any) =>
    () => {
      var CSSFinal = "";
      const showSignUpOptions = theme.showSignUpOptions;

      if (!showSignUpOptions) {
        CSSFinal += `display: none;`;
      }
      return CSSFinal;
    }};
`;

export const RowBtnLoginFb = styled(Row)`
  display: ${({ theme }: any) => (theme.show ? "" : "none")};
  width: 100%;
  --bs-gutter-x: 0rem;
`;
export const BtnLoginFacebook = styled.button`
  border-radius: 24px !important;
  width: 100%;
  height: 3rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  text-decoration: none;
  text-transform: none;
  background-color: #4268b3;
  border: none;
  //padding: 16px;
  margin-bottom: 8px;
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const SpanIConFb = styled.span`
  padding: 1px 2px 0 4px;
  margin-right: 12px;
`;

export const ImgIconFb = styled.img`
  content: url("/images/modalSignInSignUp/fb.png");
  width: 1.3rem;
  margin-right: 12px;
`;

export const RowBtnGoogle = styled(Row)`
  display: ${({ theme }: any) => (theme.show ? "" : "none")};
  width: 100%;
  --bs-gutter-x: 0rem;
`;

export const BtnLoginGoogle = styled.button`
  width: 100%;
  height: 3rem;
  text-align: center;
  border-radius: 24px !important;
  border: 1px solid #424577 !important;
  color: #4268b3 !important;
  background-color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const SpanIConGoogle = styled.span`
  padding: 1px 2px 0 4px;
  margin-right: 12px;
`;

export const ImgIconGoogle = styled.img`
  content: url("/images/modalSignInSignUp/google.png");
  width: 1.3rem;
`;

export const ColOr = styled(Col)`
  top: -9px;
  width: 40%;
  border-bottom: 1px solid #ddd;
  position: relative;
`;

export const ColOrTittle = styled(Col)`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: grey;
  //margin: 0 12px;
`;

export const RowBtnLoginMail = styled(Row)`
  width: 100%;
  --bs-gutter-x: 0rem;
`;

export const BtnLoginMail = styled.button`
  border-radius: 24px;
  height: 3rem;
  font-size: 16px;
  letter-spacing: normal;
  background-color: #123e78 !important;
  color: white;
  text-align: center;
  font-weight: 600;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const SpanIConMail = styled.span`
  //padding: 1px 2px 0 4px;
  margin-right: 0.8rem;
`;

export const ImgIconMail = styled.img`
  content: url("/images/modalSignInSignUp/arrobaIconWhite.svg");
  width: 1.1rem;
  margin-right: 0.8rem;
`;

export const DivModalFooder = styled.div`
  padding-left: 3rem;
  padding-right: 3rem;
`;

export const ModalFooder = styled(Modal.Footer)``;

export const RowFooder = styled(Row)`
  width: 100%;
  --bs-gutter-x: 0rem;
`;
export const SpanMessage = styled.span`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: grey;
  text-align: center;
`;
export const SpanSignUp = styled.span`
  color: #575757;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
`;

export const DivMainFooder = styled.div`
  margin-bottom: ${({ theme }: any) => (theme.isMobile ? "1rem" : "3rem")};
  border-top: 1px solid #dee2e6;
`;
export const DivContainerFooder = styled.div`
  margin-top: 1rem;
`;

export const ModalBodyMailRegister = styled(Modal.Body)`
  display: ${({ theme }: any) =>
    theme.bodyPassword ? "none" : theme.showMailRegister ? "" : "none"};
`;

export const ModalBodyPassword = styled(Modal.Body)`
  display: ${({ theme }: any) => (theme.bodyPassword ? "" : "none")};
`;

export const InputMail = styled.input`
  position: relative;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0;
  height: 48px;
  padding-right: 55px;
  background: #f6f8fb;
  border: 1px solid #e9e9ea;
  border-radius: 5px;
  padding-left: 35px;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c5c5c;
  display: block;
  box-sizing: border-box;
  :focus {
    outline-color: #41d976;
    outline-width: thin;
  }
`;

export const AccessCodeLabelRequired = styled.label`
  :before {
    content: "*";
    color: red;
    display: inline-block;
    position: absolute;
    left: ${({ theme }: any) => (theme.isMobile ? "-40rem" : "-18.8rem")};
    top: 1rem;
  }
`;

export const MailLabelRequired = styled.label`
  :before {
    content: "*";
    color: red;
    display: inline-block;
    position: absolute;
    left: ${({ theme }: any) => (theme.isMobile ? "-40.1rem" : "-19.1rem")};
    top: 1rem;
  }
`;

export const SpanIconArroba = styled.span`
  position: absolute;
  margin-top: -3.4rem;
  right: 2rem;
  cursor: pointer;
`;

export const ImgIconArroba = styled.img`
  content: url("/images/modalSignInSignUp/arrobaIcon.svg");
  width: 1.3rem;
`;

export const ImgIconAccessCode = styled.img`
  content: url("/images/modalSignInSignUp/accessCode.svg");
  width: 1.6rem;
`;

export const InputTittle = styled.input`
  position: relative;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0;
  height: 48px;
  padding-right: 55px;
  background: #f6f8fb;
  border: 1px solid #e9e9ea;
  border-radius: 5px;
  padding-left: 35px;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c5c5c;
  display: block;
  box-sizing: border-box;
  :focus {
    outline-color: #41d976;
    outline-width: thin;
  }
`;

export const TittleLabelRequired = styled.label`
  :before {
    content: "*";
    color: red;
    display: inline-block;
    position: absolute;
    left: ${({ theme }: any) => (theme.isMobile ? "-41.3rem" : "-20.4rem")};
    top: 1rem;
  }
`;

export const InputFirstName = styled.input`
  position: relative;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0;
  height: 48px;
  padding-right: 55px;
  background: #f6f8fb;
  border: 1px solid #e9e9ea;
  border-radius: 5px;
  padding-left: 35px;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c5c5c;
  display: block;
  box-sizing: border-box;
  :focus {
    outline-color: #41d976;
    outline-width: thin;
  }
`;

export const FirstNameLabelRequired = styled.label`
  :before {
    content: "*";
    color: red;
    display: inline-block;
    position: absolute;
    left: ${({ theme }: any) => (theme.isMobile ? "-40.1rem" : "-19.1rem")};
    top: 1rem;
  }
`;

export const SpanIconUserF1 = styled.span`
  position: absolute;
  margin-top: -3.4rem;
  right: 2rem;
  cursor: pointer;
`;

export const ImgIconUserF1 = styled.img`
  content: url("/images/modalSignInSignUp/userIconBlue.svg");
  width: 1.3rem;
`;

export const InputLastName = styled.input`
  position: relative;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0;
  height: 48px;
  padding-right: 55px;
  background: #f6f8fb;
  border: 1px solid #e9e9ea;
  border-radius: 5px;
  padding-left: 35px;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c5c5c;
  display: block;
  box-sizing: border-box;
  :focus {
    outline-color: #41d976;
    outline-width: thin;
  }
`;

export const LastNameLabelRequired = styled.label`
  :before {
    content: "*";
    color: red;
    display: inline-block;
    position: absolute;
    left: ${({ theme }: any) => (theme.isMobile ? "-40.1rem" : "-19.1rem")};
    top: 1rem;
  }
`;

export const SpanIconUserF2 = styled.span`
  position: absolute;
  margin-top: -3.4rem;
  right: 2rem;
  cursor: pointer;
`;

export const ImgIconUserF2 = styled.img`
  content: url("/images/modalSignInSignUp/userIconBlue.svg");
  width: 1.3rem;
`;

export const InputCountry = styled.input`
  position: relative;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0;
  height: 48px;
  padding-right: 55px;
  background: #f6f8fb;
  border: 1px solid #e9e9ea;
  border-radius: 5px;
  padding-left: 35px;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c5c5c;
  display: block;
  box-sizing: border-box;
  :focus {
    outline-color: #41d976;
    outline-width: thin;
  }
`;

export const RowCountry = styled(Row)`
  width: 100%;
  --bs-gutter-x: 0rem;
`;

export const DivCountry = styled.div`
  position: absolute;
  width: 93%;
  min-height: 2rem;
  max-height: 11rem;
  overflow-y: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 2px 1px #c4c5c5;
  margin-top: ${({ theme }: any) =>
    theme.type == "TittleSelection" ? "0rem" : "0rem"};
  z-index: 1;
`;

export const UlCustom = styled.ul`
  list-style-type: none;
  list-style-position: inside;
  width: 100%;
  padding: 0.5rem 1.5rem;
`;

export const LiCustom = styled.li`
  min-height: 2rem;
  //color: #686ea4;
  color: black;
  font-weight: 400;
  border-radius: 10px;
  line-height: 20px;
  //margin-left: 0.5rem;
  color: #5c5c5c;
  cursor: pointer;
  padding: 0.5rem;
`;

export const LiCustom2 = styled.li`
  min-height: 2rem;
  //color: #686ea4;
  color: black;
  font-weight: 400;
  border-radius: 10px;
  line-height: 20px;
  //margin-left: 0.5rem;
  color: #5c5c5c;
  cursor: pointer;
`;

export const RowPhone = styled(Row)`
  width: 100%;
  --bs-gutter-x: 0rem;
  margin-top: 0.7rem;
`;

export const DivPhone = styled.div`
  margin-left: 35px;
  font-size: 14px;
  color: grey;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
`;

export const InputPhoneNumber = styled.input`
  position: relative;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0;
  height: 40px;
  padding-right: 45px;
  background: #f6f8fb;
  border: 1px solid #e9e9ea;
  border-radius: 5px;
  padding-left: 35px;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c5c5c;
  display: block;
  box-sizing: border-box;
  :focus {
    outline-color: #41d976;
    outline-width: thin;
  }
`;

export const SpanIconPhone = styled.span`
  position: absolute;
  margin-top: -1.8rem;
  right: 2rem;
  cursor: pointer;
`;

export const ImgIconPhone = styled.img`
  content: url("/images/modalSignInSignUp/telephone.png");
  width: 1.1rem;
`;

export const InputListNumber = styled.input`
  position: relative;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0;
  height: 40px;
  padding-left: 45px;
  background: #f6f8fb;
  border: 1px solid #e9e9ea;
  border-radius: 5px;

  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c5c5c;
  outline: none;
`;

export const SpanFlagSelected = styled.span`
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  margin-left: ${({ theme }: any) => (theme.isMobile ? "-7rem" : "-9.2rem")};
  margin-top: 0.3rem;
`;
export const ImgFlagSelected = styled.img`
  width: 1.5rem;
  height: 1.2rem;
  object-fit: cover;
`;

export const RowListPhone = styled(Row)`
  width: 100%;
  --bs-gutter-x: 0rem;
`;

export const DivListPhone = styled.div`
  position: absolute;
  width: auto;
  min-width: 15rem;
  min-height: 2rem;
  max-height: 8rem;
  overflow-y: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 2px 1px #c4c5c5;
  margin-top: 0rem;
  z-index: 1;
`;

export const SpanImgFlag = styled.span`
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
`;

export const ImgFlag = styled.img`
  width: 1.5rem;
  height: 1.2rem;
  object-fit: cover;
`;
export const ImgFlagNotFound = styled.img`
  content: url("/images/modalSignInSignUp/photoNotFound.png");
  width: 1.5rem;
  height: 1.2rem;
  object-fit: cover;
`;

export const SpanCountryCode = styled.span`
  font-weight: 700;
  white-space: nowrap;
  position: relative;
  padding: 5px 10px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  color: #435b73;
  font-size: 14px;
  cursor: pointer;
`;

export const LiCustomPhoneList = styled.li`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  color: #435b73;
  line-height: 20px;
  height: 2rem;
  margin-top: 0.5rem;
  cursor: pointer;
  /* :hover {
    background-color: #f1f2f7;
  } */
`;

export const RowBtnSignUpCont = styled(Row)`
  width: 100%;
  --bs-gutter-x: 0rem;
`;

export const BtnContinueSignUp = styled.button`
  width: 100%;
  height: 3rem;
  margin: 0 !important;
  border-radius: 24px;
  text-transform: none;
  background-color: #404378 !important;
  border: none;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 2px;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  font-weight: 600;
  color: #fff;
`;

export const Option = styled(Row)`
  cursor: pointer;

  margin: 1rem 0.5rem;
  &:hover {
    background-color: #f1f2f7;
    border-radius: 15px;
  }
`;

export const OptionNationality = styled(Row)`
  cursor: pointer;
  margin: 0.5rem 0.5rem;
  height: 2.5rem;
  &:hover {
    background-color: #f1f2f7;
    border-radius: 15px;
  }
`;

export const InpuPassword = styled.input`
  position: relative;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0;
  height: 48px;
  padding-right: 55px;
  background: #f6f8fb;
  border: 1px solid #e9e9ea;
  border-radius: 5px;
  padding-left: 35px;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c5c5c;
  display: block;
  box-sizing: border-box;
  :focus {
    outline-color: #41d976;
    outline-width: thin;
  }
`;

export const SpanIconPassword = styled.span`
  position: absolute;
  margin-top: -2.4rem;
  right: 3.8rem;
  cursor: pointer;
`;

export const ImgIconPassword = styled.img`
  content: ${({ theme }: any) =>
    theme.showPass
      ? `url("/images/modalSignInSignUp/eyeon.svg")`
      : `url("/images/modalSignInSignUp/eyeoff.svg")`};

  width: 1.5rem;
`;

export const SpanElementRequired = styled.span`
  font-size: 11px !important;
  margin-left: 3px;
  color: red;
  font-weight: 400;
`;

export const SpanCloseBodyPassword = styled.span`
  position: relative;
  width: 2.5rem;
  margin-left: 1.5rem;
  cursor: pointer;
`;

export const ImgCloseBodyPassword = styled.img`
  content: url("/images/modalSignInSignUp/BackBlue.svg");
  width: 1.3rem;
`;

export const ModalBodyResponseSignUp = styled(Modal.Body)`
  width: 100%;
`;

export const DivResponseSignUp = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
`;
export const SpanResponseSignUp = styled.span`
  font-size: 17px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  text-align: center;
  color: #7d7d7d;
`;

export const RowTermsAndConditions = styled(Row)`
  width: 100%;
  --bs-gutter-x: 0rem;
  justify-content: inline;
`;

export const SpanNormalText = styled.span`
  font-size: 13px;
  margin-right: 0.25rem;
`;

export const LinkText = styled.a`
  font-weight: 700;
  text-decoration: underline;
  color: #686da4;
  cursor: pointer;
  outline: 0;
  font-size: 13px;
  margin-right: 0.25rem;
  &:hover {
    color: #686da4;
  }
  &:active {
    color: #686da4;
  }
`;
export const InputCheckTermsConditiosn = styled.input`
  margin-right: 0.5rem;
`;
export const TitleLabelMiddle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;
export const TitleLabel = styled.div`
  font-size: 17px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  text-align: left;
  color: #7d7d7d;
`;

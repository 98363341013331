import { useAuth } from "@contexts/AuthContext";
import { useRouter } from "next/router";
import {
  ACTION_CURRENCY,
  ACTION_LANGUAGE,
  ACTION_LOGOUT,
  ACTION_SIGIN,
  ACTION_MY_ACCOUNT,
  ACTION_TRAVEL_PROFILE,
  ACTION_MY_TRIPS,
  ACTION_BOOKING_HISTORY,
  ACTION_BOOKING_OTHER_ORG,
  COOKIE_BOOKING_OTHER_ORG,
} from "@data/menuItems";
import { useMain } from "@contexts/mainContext";
import { configStore } from "@store/configStore";
import { useMyVacationReservationStore } from "@store/useMyVacationReservationStore";
import { myReservationReedirectionProfile } from "@hooks/useMyVacationReservation";
import { removeCookies, setCookies } from "cookies-next";

export const useMenuItemsAction = () => {
  const { logout } = useAuth();
  const { functSetShowModalSignIn, setTabSelected } = useMain();
  const { getMyReservation } = useMyVacationReservationStore();
  const { setShowCurrencyModal, setShowLanguageModal } = configStore();

  const router = useRouter();

  const handleLogout = () => {
    logout();
    router.push("/");

    // refresh the page
    if (typeof window !== "undefined") {
      window.location.reload();
    }
  };

  const handleSignin = () => {
    functSetShowModalSignIn(true);
  };

  const handleShowModalCurrency = () => {
    setShowCurrencyModal(true);
  };

  const handleShowModalLanguage = () => {
    setShowLanguageModal(true);
  };

  const handleMyAccount = () => {
    /* ENDLESS REEDIRECTION */
    if (myReservationReedirectionProfile(getMyReservation())) {
      return null;
    }
    router.push({
      pathname: "/user-details",
    });
    setTabSelected("profile");
  };

  const handleTravelProfile = () => {
    router.push({
      pathname: "/user-details",
    });
    setTabSelected("travelProfile");
  };

  const handleMyTrips = () => {
    router.push({
      pathname: "/user-details",
    });
    setTabSelected("myTrips");
  };

  const handleBookingHistory = () => {
    router.push({
      pathname: "/user-details",
    });
    setTabSelected("bookingHistory");
  };

  const handleBookingOtherOrg = (data?: Object) => {
    const dataObject = data as unknown as { url: string; orgId: string };
    if (typeof dataObject == "object" && dataObject?.url && dataObject?.orgId) {
      const url = dataObject.url;
      const orgId = dataObject.orgId;

      setCookies(COOKIE_BOOKING_OTHER_ORG, orgId);
      router.push({
        pathname: url,
      });
    }
  };

  const removeOrgIdInitbookCookie = () => {
    removeCookies(COOKIE_BOOKING_OTHER_ORG);
  };

  const getAction = (key: string, data?: Object) => {
    removeOrgIdInitbookCookie();

    switch (key) {
      case ACTION_LOGOUT:
        return handleLogout();
      case ACTION_SIGIN:
        return handleSignin();
      case ACTION_CURRENCY:
        return handleShowModalCurrency();
      case ACTION_LANGUAGE:
        return handleShowModalLanguage();
      case ACTION_MY_ACCOUNT:
        return handleMyAccount();
      case ACTION_TRAVEL_PROFILE:
        return handleTravelProfile();
      case ACTION_MY_TRIPS:
        return handleMyTrips();
      case ACTION_BOOKING_HISTORY:
        return handleBookingHistory();
      case ACTION_BOOKING_OTHER_ORG:
        return handleBookingOtherOrg(data);
      default:
        return () => {};
    }
  };

  return { getAction, removeOrgIdInitbookCookie };
};

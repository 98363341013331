import {
  ModalCustom,
  RowCloseModalSignUp,
  SpanCloseModalSignUp,
  ImgCloseModalSignUp,
  DivModalHeader,
  ModalHeader,
  RowIconSignUp,
  SpanIconSignUp,
  ColIconSignUp,
  DivModalBody,
  ModalBody,
  RowBtnLoginFb,
  BtnLoginFacebook,
  ImgIconFb,
  RowBtnGoogle,
  BtnLoginGoogle,
  SpanIConGoogle,
  ImgIconGoogle,
  ColOr,
  ColOrTittle,
  RowBtnLoginMail,
  BtnLoginMail,
  ImgIconMail,
  SpanMessage,
  SpanSignUp,
  ModalBodyMailRegister,
  InputMail,
  SpanIconArroba,
  ImgIconArroba,
  MailLabelRequired,
  InputTittle,
  TittleLabelRequired,
  InputFirstName,
  FirstNameLabelRequired,
  SpanIconUserF1,
  ImgIconUserF1,
  InputLastName,
  LastNameLabelRequired,
  SpanIconUserF2,
  ImgIconUserF2,
  InputCountry,
  RowCountry,
  DivCountry,
  UlCustom,
  LiCustom,
  DivPhone,
  RowPhone,
  InputPhoneNumber,
  SpanIconPhone,
  ImgIconPhone,
  RowBtnSignUpCont,
  BtnContinueSignUp,
  OptionNationality,
  ColIconSignUpMode,
  ModalBodyPassword,
  InpuPassword,
  SpanIconPassword,
  ImgIconPassword,
  SpanElementRequired,
  SpanCloseBodyPassword,
  ImgCloseBodyPassword,
  ModalBodyResponseSignUp,
  DivResponseSignUp,
  SpanResponseSignUp,
  DivMainFooder,
  DivContainerFooder,
  ImgIconAccessCode,
  RowTermsAndConditions,
  SpanNormalText,
  LinkText,
  InputCheckTermsConditiosn,
  AccessCodeLabelRequired,
  TitleLabelMiddle,
  TitleLabel,
  LiCustom2,
} from "./styles";
import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import axios from "axios";
import { TITLES } from "@data/selects/title";
import { getCookie } from "cookies-next";
import { useContryList, useWhiteLabelLan } from "@hooks/useLayout";
import {
  FACEBOOK_APP_ID,
  GOOGLE_APP_ID,
  SITE_ID,
  SHOW_GOOGLE,
  SHOW_FACEBOOK,
  SHOW_REGISTER_CODE,
} from "@helpers/config";
import { AutoCompletePhone } from "@components/Weeks/Details/Checkout/AutoCompletePhone";
import {
  getIdCountryDefault,
  getIdCountryFindCountryCode,
  getIdNationDefault,
  getIdNationFindCountryCode,
  preventExecuteCodeHTMLJS,
  EXPRESSION_FIRST_LAST_NAME,
} from "@helpers/helper";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";

type ModalSignUpProps = {
  show: boolean;
  handleCloseModalSignUp: any;
  handleOpenModalSignIn: any;
  isMobile: boolean;
  showMailRegister: boolean;
  hanndleSetShowMailRegister: (shows: boolean) => void;
  showMailRegisterSignUpMode: any;
  handdleShowMailRegisterSignUpMode: (shows: any) => void;
  bodyPassword: boolean;
  handdleSetBodyPassword: (shows: boolean) => void;
  accesscode: string;
  messageSignUp: string;
  handelSetMessageSignUp: (message: string) => void;
  infoInvitation: any;
  showSignUpOptions: boolean;
  handleSetShowSignUpOptions: (state: boolean) => void;
};

/* type CountryProps = {
  countryCode: string;
  countryFlag: string;
  countryId: number;
  countryName: string;
  default_country: string;
  distanceUnit: string;
  isdCode: string;
  tempUnit: string;
}; */

export const ModalSignUp = ({
  show,
  handleCloseModalSignUp,
  handleOpenModalSignIn,
  isMobile,
  showMailRegister,
  hanndleSetShowMailRegister,
  showMailRegisterSignUpMode,
  handdleShowMailRegisterSignUpMode,
  bodyPassword,
  handdleSetBodyPassword,
  accesscode,
  messageSignUp,
  handelSetMessageSignUp,
  infoInvitation,
  showSignUpOptions,
  handleSetShowSignUpOptions,
}: ModalSignUpProps) => {
  useContryList();
  const { countryList } = useLayoutStore(
    (state) => ({
      countryList: state.countryList,
    }),
    shallow
  );

  const [showNationality, setShowNationality] = useState(false);
  //const [dataCountry, setDataCountry] = useState(countryJSON.data.data);
  const [contryNameSelectedID, setCountryNameSelectedID] = useState<
    number | undefined
  >(undefined);
  const [nationality, setNationality] = useState("");
  const [nationalityID, setNationalityID] = useState<number | null>(null);
  const [showListTittle, setShowListTittle] = useState(false);
  const [listTittle, setListTittle] = useState("");
  const [listTittleLabel, setListTittleLabel] = useState("");

  const [accessCode, setAccessCode] = useState("");
  const [mail, setMail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [celPhone, setCelPhone] = useState("");

  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [reqPass, setReqPass] = useState("");
  const [reqTermCond, setReqTermCond] = useState("");

  const [pass, setPass] = useState(""); //Reddragon9.
  const [pass2, setPass2] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const [errorSignIn, setErrorSignIn] = useState("");
  const [reqEmail, setReqEmail] = useState("");
  const [reqTittle, setReqTittle] = useState("");
  const [reqFirstName, setReqFirstName] = useState("");
  const [reqLastName, setReqLastName] = useState("");
  const [reqAccessCode, setReqAccessCode] = useState("");
  const [respAccessCode, setRespAccessCode] = useState("");
  const { getLangLabel } = useWhiteLabelLan();

  const [nationalityCat, setNationalityCat] = useState<any>(null);
  const [nationalityCatFilter, setNationalityCatFilter] = useState<any>(null);

  const [countryCat, setCountryCat] = useState<any>(null);
  const [countryIsdCode, setCountryIsdCode] = useState("");

  const getCountryCat = async () => {
    const response = await axios.post("/api/getCountryCat");
    //console.log(`getCountryCat response: `, response?.data?.data?.data);
    setCountryCat(response?.data?.data?.data);
  };

  const DivFooder = () => {
    return (
      <SpanMessage>
        {/* Already have a Travelwithsky Account?{" "} */}
        {getLangLabel("ALREADY HAVE AN IJF ACCOUNT")}?{" "}
        <SpanSignUp
          onClick={() => {
            handleCloseModalSignUp();
            handleOpenModalSignIn();
          }}
        >
          {getLangLabel("SIGN-IN")}
        </SpanSignUp>
      </SpanMessage>
    );
  };
  const getNationality = async () => {
    const response = await axios.post("/api/getNationalityCat");
    //console.log(`getNationalityCat response: `, response?.data?.data?.data);
    setNationalityCat(response?.data?.data?.data);
    setNationalityCatFilter(response?.data?.data?.data);
  };

  const NationalitySelection = () => {
    if (nationalityCatFilter) {
      return (
        <DivCountry
          onClick={() => {
            setShowNationality(!showNationality);
          }}
          onMouseLeave={() => {
            setShowNationality(false);
          }}
          className={"scrollModify"}
          theme={{ type: "CountrySelection" }}
        >
          <UlCustom>
            {nationalityCatFilter.map((obj: any, index: number) => {
              return (
                <OptionNationality key={`countryS_${index}`}>
                  <LiCustom
                    onClick={() => {
                      setNationality(obj?.nationalityName || "");
                      setNationalityID(Number(obj?.nationalityId || 0));
                    }}
                  >
                    {obj.nationalityName}
                  </LiCustom>
                </OptionNationality>
              );
            })}
          </UlCustom>
        </DivCountry>
      );
    }
    return <></>;
  };

  const TittleSelection = () => {
    return (
      <DivCountry
        onClick={() => {
          setShowListTittle(!showListTittle);
        }}
        className={"scrollModify"}
        theme={{ type: "TittleSelection" }}
      >
        <UlCustom>
          {TITLES.map((obj: any, index: number) => {
            return (
              <OptionNationality key={`tittleS_${index}`}>
                <LiCustom2
                  onClick={() => {
                    setShowListTittle(!showListTittle);
                    setListTittle(String(obj.value));
                    setListTittleLabel(
                      `${getLangLabel(String(obj.label).toUpperCase())}`
                    );
                  }}
                >
                  <TitleLabelMiddle>
                    <TitleLabel>
                      {`${getLangLabel(String(obj.label).toUpperCase())}`}
                    </TitleLabel>
                  </TitleLabelMiddle>
                </LiCustom2>
              </OptionNationality>
            );
          })}
        </UlCustom>
      </DivCountry>
    );
  };

  const filterOnchange = (value: string) => {
    var data_tmp: any[] = [];
    if (value) {
      nationalityCatFilter?.map((obj: any) => {
        let nationalityName = obj.nationalityName.toLowerCase();
        if (nationalityName.startsWith(value.toLowerCase())) {
          data_tmp.push(obj);
        }
      });
      setNationalityCatFilter(data_tmp);
    } else {
      setNationalityCatFilter(nationalityCat);
      setShowNationality(false);
    }
  };

  const cleanLabelsSIgnUp = () => {
    setListTittle("");
    setNationality("");
    //setCountrySelected("");
    //setCountryNameSelected("");
  };
  const executeUserExists = async (
    email: string,
    from: "Facebook" | "Google" | "Mail",
    jsonLoggedType: any
  ) => {
    let jsonSigUp = {
      email_id: email,
    };
    const response = await axios.post(`/api/signUp/checkAccountForExists`, {
      jsonSigUp,
    });

    /* console.log(`executeUserExists()_jsonSigUp: `, { jsonSigUp });

    console.log(`executeUserExists()_ response.data: `, response.data);

    console.log(`executeUserExists()_ jsonLoggedType: `, jsonLoggedType); */

    if (response?.data?.data?.data?.code == 404) {
      //el usuario no existe y permite asignar el SignUp su informacion por primera vez
      if (from == "Facebook") {
        handdleShowMailRegisterSignUpMode({
          from: "Facebook",
          userFirstName: jsonLoggedType.name.split(" ")[0].trim()
            ? jsonLoggedType.name.split(" ")[0].trim()
            : "",
          userLastName: jsonLoggedType.name.split(" ")[1].trim()
            ? jsonLoggedType.name.split(" ")[1].trim()
            : "",
          email: jsonLoggedType?.email,
          googleToken: "",
          faceBookToken: jsonLoggedType.accessToken,
          picture: jsonLoggedType?.picture?.data?.url,
        });
      }
      if (from == "Google") {
        handdleShowMailRegisterSignUpMode({
          from: "Google",
          userFirstName: jsonLoggedType.profileObj.givenName,
          userLastName: jsonLoggedType.profileObj.familyName,
          email: jsonLoggedType.profileObj.email,
          //googleToken: jsonLoggedType.tokenObj.access_token,
          googleToken: jsonLoggedType.profileObj.googleId,
          faceBookToken: "",
          picture: jsonLoggedType.profileObj.imageUrl,
        });
      }
      if (from == "Mail") {
        handdleShowMailRegisterSignUpMode({
          from: "Mail",
          userFirstName: firstName.trim(),
          userLastName: lastName.trim(),
          email: mail,
          googleToken: "",
          faceBookToken: "",
          picture: "",
        });
      }
      cleanLabelsSIgnUp();
      hanndleSetShowMailRegister(true);
    } else if (response?.data?.data?.data?.code == 201) {
      setRespAccessCode(response?.data?.data?.data?.resp);
    }
  };

  const validateNumber = (letter: string) => {
    let validateNumber = false;
    if (Number(letter) || Number(letter) == 0) {
      validateNumber = true;
    }
    return validateNumber;
  };

  const validateEmail = (email: String) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const passValidate1LoweCase = (password: string) => {
    return password.match(/(?=.*[a-z])/);
  };
  const passValidate1UpperCase = (password: string) => {
    return password.match(/(?=.*[A-Z])/);
  };
  const passValidate1Number = (password: string) => {
    return password.match(/(?=.*\d)/);
  };
  const passValidate1SpecialCaracter = (password: string) => {
    return password.match(/(?=.*\W)/);
  };

  const checkLabelRequired = () => {
    /* console.log(
      `checkLabelRequired()_ mail: ${mail}, pass:${pass} , pass2: ${pass2}`
    ); */
    let pasaValidacion: boolean = true;

    if (accessCode == "" || accessCode == null) {
      setReqAccessCode("Please Enter The Access Code");
      pasaValidacion = false;
    }
    if (mail == "" || mail == null) {
      setReqEmail("Please Enter Email ID");
      pasaValidacion = false;
    } else if (!validateEmail(mail)) {
      setReqEmail("Invalid Mail");
      pasaValidacion = false;
    }

    if (listTittle == "") {
      setReqTittle("Please Select Title");
      pasaValidacion = false;
    }
    if (firstName == "") {
      setReqFirstName("Please Enter First Name");
      pasaValidacion = false;
    }
    if (lastName == "") {
      setReqLastName("Please Enter Last Name");
      pasaValidacion = false;
    }

    return pasaValidacion;
  };

  const validateRegisterInfoClientFields = () => {
    if (checkLabelRequired()) {
      //se valida el accessCode proporcionado
      validateAccessCode();
    }
  };

  const validateAccessCode = async () => {
    let jsonRegister = {
      promotions: {
        isPromotionVisited: false,
      },
      accesscode: accessCode.trim(),
      email_id: mail,
      password: "",
      googletoken: "",
      fbtoken: "",
      nationality: nationalityID,
      site_url: "",
      mastercode: "",
      language: "en-US",
      title: listTittle,
      client_ip_address: String(getCookie("ip")),
      true_client_ip: String(getCookie("ip")),
      userdetails: {
        email_id: mail,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        country_code: contryNameSelectedID || getIdCountryDefault(countryList),
        mobile_no: String(celPhone),
        mobile_country_code: String(
          countryIsdCode || getIdCountryDefault(countryList)
        ),
      },
    };
    const response = await axios.post(`/api/signUp/validateAccessCode`, {
      jsonRegister,
    });

    if (response?.data?.data?.data?.status == false) {
      setRespAccessCode(response.data.data.data.resp);
    }
    if (response?.data?.data?.data?.status) {
      if (
        showMailRegisterSignUpMode?.from == "Google" ||
        showMailRegisterSignUpMode?.from == "Facebook"
      ) {
        executeRegister();
      } else {
        handdleSetBodyPassword(true);
      }

      hanndleSetShowMailRegister(true);
    }
  };

  const validateRegisterPasswordFields = () => {
    if (!termsAndConditions) {
      setReqTermCond(
        getLangLabel("PLEASE ACCEPT THE TERMS & CONDITIONS TO PROCEED")
      );
      return;
    }
    if (pass !== pass2 || pass2 !== pass) {
      setErrorSignIn("Passwords Do Not Match");
      return;
    }
    if (
      !passValidate1LoweCase(pass) ||
      !passValidate1UpperCase(pass) ||
      !passValidate1Number(pass) ||
      !passValidate1SpecialCaracter(pass)
    ) {
      setErrorSignIn(
        getLangLabel(
          "PASSWORD MUST BE BETWEEN 8-13 CHARACTERS IN LENGTH AND MUST COMPRISE OF AT LEAST 1 LOWERCASE ALPHABET (A-Z), 1 UPPERCASE ALPHABET (A-Z), 1 NUMBER (0-9) AND 1 SPECIAL CHARACTER"
        )
      );
      return;
    }
    executeRegister();
  };

  const executeRegister = async () => {
    let jsonRegister = {
      promotions: {
        isPromotionVisited: false,
      },
      accesscode: accessCode ? accessCode : accesscode,
      email_id: mail,
      password: pass,
      googletoken:
        showMailRegisterSignUpMode?.from == "Google"
          ? showMailRegisterSignUpMode?.googleToken
          : "",
      fbtoken:
        showMailRegisterSignUpMode?.from == "Facebook"
          ? showMailRegisterSignUpMode?.faceBookToken
          : "",
      nationality: nationalityID,
      site_url: "",
      mastercode: "",
      language:
        infoInvitation?.lang || String(getCookie("lan_code") || "en-US"),
      title: listTittle,
      site_id: SITE_ID,
      client_ip_address: String(getCookie("ip")),
      trip_id: infoInvitation?.tripid || "",
      userdetails: {
        email_id: mail,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        country_code: contryNameSelectedID || getIdCountryDefault(countryList),
        mobile_no: String(celPhone),
        mobile_country_code: String(
          contryNameSelectedID || getIdCountryDefault(countryList)
        ),
      },
    };
    console.log({ jsonRegister });
    const response = await axios.post(`/api/signUp/`, {
      jsonRegister,
    });

    if (
      response?.data?.status == false ||
      (response?.data?.status == true && response?.data?.data?.code == 201) ||
      response?.data?.data?.code == 500
    ) {
      const message: string = String(
        response?.data?.data?.resp || response?.data?.resp || ""
      );
      const traduction: string = getLangLabel(message.toUpperCase());
      handelSetMessageSignUp(traduction || "");
    } else if (
      response?.data?.status == true &&
      response?.data?.data?.isProfileCreated == true
    ) {
      if (
        showMailRegisterSignUpMode?.from == "Google" ||
        showMailRegisterSignUpMode?.from == "Facebook"
      ) {
        handleSetShowSignUpOptions(false);
        hanndleSetShowMailRegister(false);
        handdleShowMailRegisterSignUpMode(false);
        handelSetMessageSignUp("EXECUTE_GOOGLE_FACEBOOK_MESSAGE");
      } else {
        handelSetMessageSignUp(
          getLangLabel(
            "AN EMAIL CONFIRMATION HAS BEEN SENT TO YOUR ACCOUNT. PLEASE ACCEPT TO CONTINUE YOUR REGISTRATION PROCESS"
          )
        );
      }
    }
  };

  const responseFacebook = (response: any) => {
    //console.log(`responseFacebook()_`, response);
    if (response?.accessToken) {
      executeUserExists(response?.email, "Facebook", response);
    }
    if (response?.name) {
      try {
        const name: string[] = response?.name.split(" ");
        setFirstName(name[0].toString());
        if (name.length >= 2) {
          setLastName(name[1].toString());
        }
      } catch (e: any) {
        console.log(
          `error al tiempo de auto poner nombre y apellido por Facebook`,
          { error: e }
        );
      }
    }
  };
  const responseGoogleSuccess = (response: any) => {
    //console.log(`responseGoogleSuccess()_`, { response });
    executeUserExists(response.profileObj.email, "Google", response);
    setFirstName(response.profileObj.givenName);
    setLastName(response.profileObj.familyName);
  };

  const responseGoogleError = (response: any) => {
    console.log(`responseGoogleError()_`, response);
  };

  const responseMail = () => {
    //console.log(`responseMail`);
    //executeUserExists("", "Mail", "");
  };

  function handleSubmit(event: any) {
    event.preventDefault();
  }
  const getWelcomeMessageFaceBookGoogle = () => {
    let welcomeMessage: string = "";
    if (getLangLabel("WELCOMEMESSAGE").toUpperCase() != "WELCOMEMESSAGE") {
      welcomeMessage = getLangLabel("WELCOMEMESSAGE");
    }
    return welcomeMessage;
  };

  const getWelcomeMessageFaceBookGoogleSignUpSuccess = () => {
    let SIGNUPSUCCESS: string = "";
    if (getLangLabel("SIGNUPSUCCESS").toUpperCase() != "SIGNUPSUCCESS") {
      SIGNUPSUCCESS = getLangLabel("SIGNUPSUCCESS");
    }
    return SIGNUPSUCCESS;
  };

  useEffect(() => {
    if (!nationalityCat) {
      getNationality();
    }
  }, [nationalityCat]);

  useEffect(() => {
    if (!countryCat) {
      getCountryCat();
    }
  }, [countryCat]);

  useEffect(() => {
    if (
      showMailRegisterSignUpMode?.from == "Google" ||
      showMailRegisterSignUpMode?.from == "Facebook"
    ) {
      setMail(showMailRegisterSignUpMode?.email);
    }
  }, [showMailRegisterSignUpMode?.email, showMailRegisterSignUpMode?.from]);

  useEffect(() => {
    if (!accessCode) {
      setAccessCode(accesscode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accesscode]);

  const selectListTitle = (pos: number = 0) => {
    const index = pos > 0 && pos <= TITLES.length ? pos - 1 : 0;
    const titleSelect = TITLES.at(index);
    setListTittle(titleSelect?.value || "");
    setListTittleLabel(titleSelect?.label || "");
  };

  useEffect(() => {
    if (infoInvitation) {
      setAccessCode(infoInvitation?.mastercode);
      setMail(infoInvitation?.email);
      setFirstName(infoInvitation?.firstname);
      setLastName(infoInvitation?.lastname);
      selectListTitle(Number(infoInvitation?.title));
      const numberPhone = String(infoInvitation?.phonenumber);
      if (validateNumber(numberPhone)) {
        setCelPhone(numberPhone);
      }
      const codeFronQuery = infoInvitation?.countrycode || "";
      if (codeFronQuery) {
        const dataCountryInfo = getIdCountryFindCountryCode(
          countryList,
          codeFronQuery
        );

        if (dataCountryInfo) {
          setCountryNameSelectedID(dataCountryInfo.countryId);
          setCountryIsdCode(dataCountryInfo.isdCode);
        }
      }
    }
  }, [countryList, infoInvitation]);

  /* console.log(
    `accessCode(From INPUT): |${accessCode}| ,accesscode(from API): |${accesscode}| , infoInvitation: `,
    infoInvitation
  ); */

  //Se agrega UseEffect para preseleccionar valor de nacion por default
  useEffect(() => {
    const codeFronQuery = infoInvitation?.countrycode || "";
    const dataNationInfo = getIdNationFindCountryCode(
      nationalityCat,
      codeFronQuery
    );

    if (dataNationInfo) {
      setNationality(dataNationInfo?.nationalityName || "");
      setNationalityID(Number(dataNationInfo?.nationalityId));
    } else {
      const dataNation = getIdNationDefault(nationalityCat);

      setNationalityID(dataNation?.nationalityId || 1);
      setNationality(dataNation?.nationalityName || "");
    }
  }, [nationalityCat, infoInvitation]);

  return (
    <ModalCustom
      show={show}
      onHide={handleCloseModalSignUp}
      fullscreen={isMobile}
      theme={{ isMobile }}
      backdrop={"static"}
      keyboard={false}
      className="modalcolors"
    >
      <RowCloseModalSignUp theme={{ bodyPassword }}>
        {bodyPassword && (
          <SpanCloseBodyPassword
            onClick={() => {
              handdleSetBodyPassword(false);
            }}
          >
            <ImgCloseBodyPassword />
          </SpanCloseBodyPassword>
        )}

        <SpanCloseModalSignUp
          onClick={() => {
            handleCloseModalSignUp();
          }}
        >
          <ImgCloseModalSignUp />
        </SpanCloseModalSignUp>
      </RowCloseModalSignUp>
      <DivModalHeader>
        <ModalHeader>
          <RowIconSignUp>
            <Col md={12} className={"p-0"}>
              <SpanIconSignUp />
            </Col>
            {!showMailRegisterSignUpMode ? (
              <>
                <ColIconSignUp md={12} className={"p-0"}>
                  {getLangLabel("SIGN-UP")}
                </ColIconSignUp>
                <ColIconSignUp md={12} className={"p-0"}>
                  {messageSignUp == "EXECUTE_GOOGLE_FACEBOOK_MESSAGE"
                    ? getWelcomeMessageFaceBookGoogleSignUpSuccess()
                    : ""}
                </ColIconSignUp>
              </>
            ) : (
              <>
                <ColIconSignUpMode
                  xs={12}
                  md={12}
                  className={"p-0"}
                  theme={{ type: "MainTittle" }}
                >
                  {getLangLabel("COMPLETE YOUR INFORMATION")}
                </ColIconSignUpMode>
                <ColIconSignUpMode
                  xs={12}
                  md={12}
                  className={"p-0"}
                  theme={{ type: "SecondTittle" }}
                >
                  {getLangLabel(
                    "PLEASE REVIEW AND COMPLETE ANY MISSING INFORMATION"
                  )}
                </ColIconSignUpMode>
              </>
            )}
          </RowIconSignUp>
        </ModalHeader>
      </DivModalHeader>
      <DivModalBody>
        {!messageSignUp ? (
          <>
            {respAccessCode && (
              <SpanElementRequired>{respAccessCode}</SpanElementRequired>
            )}
            <ModalBody
              theme={{ showMailRegister, bodyPassword, showSignUpOptions }}
            >
              <div style={{ marginBottom: "0.5rem" }}>
                <RowBtnLoginFb theme={{ show: SHOW_FACEBOOK }}>
                  <Col>
                    <FacebookLogin
                      appId={`${FACEBOOK_APP_ID}`}
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      render={(renderProps) => (
                        <BtnLoginFacebook
                          className="btnfacebook"
                          onClick={renderProps.onClick}
                        >
                          <ImgIconFb />
                          {getLangLabel("CONTINUE WITH FACEBOOK")}
                        </BtnLoginFacebook>
                      )}
                    />
                  </Col>
                </RowBtnLoginFb>
              </div>
              <div style={{ marginBottom: "0.5rem" }}>
                <RowBtnGoogle theme={{ show: SHOW_GOOGLE }}>
                  <GoogleLogin
                    clientId={`${GOOGLE_APP_ID}`}
                    isSignedIn={false}
                    render={(renderProps) => (
                      <BtnLoginGoogle
                        className="btngoogle"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <SpanIConGoogle>
                          <ImgIconGoogle />
                        </SpanIConGoogle>
                        {getLangLabel("CONTINUE WITH GOOGLE")}
                      </BtnLoginGoogle>
                    )}
                    buttonText="Login"
                    onSuccess={(e) => {
                      responseGoogleSuccess(e);
                    }}
                    onFailure={(e) => {
                      responseGoogleError(e);
                    }}
                    cookiePolicy={"single_host_origin"}
                  />
                </RowBtnGoogle>
              </div>
              <div
                style={{
                  marginBottom: "1rem",
                  display:
                    SHOW_GOOGLE == false && SHOW_FACEBOOK == false
                      ? "none"
                      : "block",
                }}
              >
                <Row>
                  <ColOr xs={5} md={5}></ColOr>
                  <ColOrTittle xs={2} md={2}>
                    o
                  </ColOrTittle>
                  <ColOr xs={5} md={5}></ColOr>
                </Row>
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <RowBtnLoginMail>
                  <BtnLoginMail
                    className="btnmail"
                    onClick={() => {
                      hanndleSetShowMailRegister(!showMailRegister);
                      responseMail();
                      setRespAccessCode("");
                    }}
                  >
                    <ImgIconMail />
                    {getLangLabel("SIGN UP WITH EMAIL")}
                  </BtnLoginMail>
                </RowBtnLoginMail>
              </div>
            </ModalBody>
            <ModalBodyMailRegister theme={{ showMailRegister, bodyPassword }}>
              {SHOW_REGISTER_CODE == true && (
                <div style={{ marginBottom: "1.5rem" }}>
                  <InputMail
                    type={"text"}
                    placeholder={getLangLabel("ACCESS CODE")}
                    onChange={(e) => {
                      setReqAccessCode("");
                      setRespAccessCode("");
                      setAccessCode(
                        preventExecuteCodeHTMLJS(String(e.target.value || ""))
                      );
                    }}
                    onBlur={() => {
                      if (!accessCode) {
                        setAccessCode(accesscode);
                      }
                    }}
                    value={accessCode ? accessCode.trim() : ""}
                    disabled={infoInvitation?.mastercode ? true : false}
                  />
                  <SpanIconArroba>
                    <AccessCodeLabelRequired theme={{ isMobile }} />
                    <ImgIconAccessCode />
                  </SpanIconArroba>
                  {reqAccessCode && (
                    <SpanElementRequired>{reqAccessCode}</SpanElementRequired>
                  )}
                </div>
              )}
              <div style={{ marginBottom: "1.5rem" }}>
                <InputMail
                  type={"text"}
                  placeholder={getLangLabel("EMAIL ADDRESS")}
                  onChange={(e) => {
                    setReqEmail("");
                    setMail(
                      preventExecuteCodeHTMLJS(String(e.target.value || ""))
                    );
                  }}
                  value={
                    showMailRegisterSignUpMode?.email
                      ? showMailRegisterSignUpMode?.email
                      : mail
                  }
                  disabled={showMailRegisterSignUpMode?.email ? true : false}
                />

                <SpanIconArroba>
                  <MailLabelRequired theme={{ isMobile }} />
                  <ImgIconArroba />
                </SpanIconArroba>
                {reqEmail && (
                  <SpanElementRequired>{reqEmail}</SpanElementRequired>
                )}
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <Row>
                  <Col>
                    <InputTittle
                      type={"text"}
                      placeholder={getLangLabel("TITLE")}
                      onClick={() => {
                        setReqTittle("");
                        setShowListTittle(true);
                      }}
                      value={listTittleLabel}
                      readOnly
                    />
                    <SpanIconArroba>
                      <TittleLabelRequired theme={{ isMobile }} />
                    </SpanIconArroba>
                    {showListTittle && <TittleSelection />}
                    {reqTittle && (
                      <SpanElementRequired>{reqTittle}</SpanElementRequired>
                    )}
                  </Col>
                </Row>
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <Row>
                  <Col>
                    <InputFirstName
                      type={"text"}
                      placeholder={getLangLabel("FIRST NAME")}
                      onChange={(e) => {
                        setReqFirstName("");
                        const name: string = String(e.target.value);
                        if (EXPRESSION_FIRST_LAST_NAME.test(name)) {
                          setFirstName(name);
                        }
                      }}
                      value={firstName}
                    />

                    <SpanIconUserF1>
                      <FirstNameLabelRequired theme={{ isMobile }} />
                      <ImgIconUserF1 />
                    </SpanIconUserF1>
                    {reqFirstName && (
                      <SpanElementRequired>{reqFirstName}</SpanElementRequired>
                    )}
                  </Col>
                </Row>
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <Row>
                  <Col>
                    <InputLastName
                      type={"text"}
                      placeholder={getLangLabel("LAST NAME")}
                      onChange={(e) => {
                        setReqLastName("");
                        const lastName: string = String(e.target.value);
                        if (EXPRESSION_FIRST_LAST_NAME.test(lastName)) {
                          setLastName(lastName);
                        }
                      }}
                      value={lastName}
                    />

                    <SpanIconUserF2>
                      <LastNameLabelRequired theme={{ isMobile }} />
                      <ImgIconUserF2 />
                    </SpanIconUserF2>
                    {reqLastName && (
                      <SpanElementRequired>{reqLastName}</SpanElementRequired>
                    )}
                  </Col>
                </Row>
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <RowCountry>
                  <Col>
                    <InputCountry
                      type={"text"}
                      placeholder={getLangLabel("SELECT NATIONALITY")}
                      onClick={() => {
                        setShowNationality(true);
                        //setShowListNumber(false);
                      }}
                      onChange={(event) => {
                        setShowNationality(true);
                        filterOnchange(
                          preventExecuteCodeHTMLJS(
                            String(event.target.value || "")
                          )
                        );
                        setNationality(
                          preventExecuteCodeHTMLJS(
                            String(event.target.value || "")
                          )
                        );
                      }}
                      onFocusCapture={() => {
                        setNationalityCatFilter(nationalityCat);
                      }}
                      autoComplete={"off"}
                      value={nationality}
                    />
                    {showNationality && <NationalitySelection />}
                  </Col>
                </RowCountry>
              </div>
              <div style={{ marginBottom: "2rem" }}>
                <RowPhone>
                  <Col xs={12} md={12}>
                    <DivPhone>{getLangLabel("CELL PHONE NUMBER")}</DivPhone>
                  </Col>
                </RowPhone>
                <RowPhone>
                  <Col xs={5} md={5}>
                    <AutoCompletePhone
                      value={
                        contryNameSelectedID || getIdCountryDefault(countryList)
                      }
                      onChanges={(val: CountryListType) => {
                        setCountryNameSelectedID(val.countryId);
                        setCountryIsdCode(val.isdCode);
                      }}
                    />
                  </Col>
                  <Col xs={1} md={1}></Col>
                  <Col xs={6} md={6}>
                    <InputPhoneNumber
                      type={"text"}
                      placeholder={getLangLabel("NUMBER")}
                      maxLength={15}
                      onChange={(e) => {
                        if (validateNumber(String(e.target.value))) {
                          setCelPhone(String(e.target.value));
                        }
                      }}
                      value={celPhone.trim()}
                    />
                    <SpanIconPhone>
                      <ImgIconPhone />
                    </SpanIconPhone>
                  </Col>
                </RowPhone>
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <RowBtnSignUpCont>
                  <BtnContinueSignUp
                    onClick={() => {
                      validateRegisterInfoClientFields();
                    }}
                  >
                    {getLangLabel("CONTINUE")}
                  </BtnContinueSignUp>
                </RowBtnSignUpCont>
              </div>
            </ModalBodyMailRegister>
            <ModalBodyPassword theme={{ bodyPassword }}>
              {errorSignIn && (
                <SpanElementRequired>{errorSignIn}</SpanElementRequired>
              )}
              <form onSubmit={handleSubmit} autoComplete={"off"}>
                <div style={{ marginBottom: isMobile ? "2rem" : "1rem" }}>
                  <InpuPassword
                    type={showPass ? "text" : "password"}
                    name={"password1"}
                    placeholder="Password"
                    maxLength={13}
                    onChange={(e) => {
                      setReqPass("");
                      setErrorSignIn("");
                      setPass(String(e.target.value));
                    }}
                    autoComplete={"off"}
                    value={pass}
                  />
                  <SpanIconPassword
                    onClick={() => {
                      setShowPass(!showPass);
                    }}
                  >
                    <ImgIconPassword theme={{ showPass: showPass }} />
                  </SpanIconPassword>
                  {reqPass && (
                    <SpanElementRequired>{reqPass}</SpanElementRequired>
                  )}
                </div>
                <div style={{ marginBottom: isMobile ? "2rem" : "1rem" }}>
                  <InpuPassword
                    type={showPass2 ? "text" : "password"}
                    name={"password2"}
                    placeholder="Password"
                    maxLength={13}
                    onChange={(e) => {
                      setReqPass("");
                      setErrorSignIn("");
                      setPass2(String(e.target.value));
                    }}
                    autoComplete={"off"}
                    value={pass2}
                  />
                  <SpanIconPassword
                    onClick={() => {
                      setShowPass2(!showPass2);
                    }}
                  >
                    <ImgIconPassword theme={{ showPass: showPass2 }} />
                  </SpanIconPassword>
                  {reqPass && (
                    <SpanElementRequired>{reqPass}</SpanElementRequired>
                  )}
                </div>
                <div style={{ marginBottom: "1rem" }}>
                  <RowTermsAndConditions>
                    <Col>
                      <InputCheckTermsConditiosn
                        type={"checkbox"}
                        onClick={() => {
                          setReqTermCond("");
                        }}
                        onChange={() => {
                          setTermsAndConditions(!termsAndConditions);
                        }}
                        checked={termsAndConditions}
                      />
                      <SpanNormalText>
                        {getLangLabel("I HAVE READ AND ACCEPT")}
                      </SpanNormalText>
                      <LinkText href="/privacy-policy">
                        {getLangLabel("PRIVACY POLICIES")}
                      </LinkText>
                      <SpanNormalText>{getLangLabel("AND")}</SpanNormalText>
                      <LinkText href="/terms-of-use">
                        {getLangLabel("TERMS & CONDITIONS")}
                      </LinkText>
                    </Col>
                    {reqTermCond && (
                      <SpanElementRequired>{reqTermCond}</SpanElementRequired>
                    )}
                  </RowTermsAndConditions>
                </div>
                <div style={{ marginBottom: "1rem" }}>
                  <RowBtnSignUpCont>
                    <BtnContinueSignUp
                      onClick={() => {
                        validateRegisterPasswordFields();
                      }}
                      type={"submit"}
                    >
                      {getLangLabel("SIGN-UP")}
                    </BtnContinueSignUp>
                  </RowBtnSignUpCont>
                </div>
              </form>
            </ModalBodyPassword>
          </>
        ) : (
          <ModalBodyResponseSignUp>
            <DivResponseSignUp>
              <SpanResponseSignUp>{`${
                messageSignUp == "EXECUTE_GOOGLE_FACEBOOK_MESSAGE"
                  ? getWelcomeMessageFaceBookGoogle()
                  : getLangLabel(messageSignUp.toUpperCase())
              }`}</SpanResponseSignUp>
            </DivResponseSignUp>
          </ModalBodyResponseSignUp>
        )}
        <DivMainFooder className={"text-center"} theme={{ isMobile }}>
          <DivContainerFooder>
            <DivFooder />
          </DivContainerFooder>
        </DivMainFooder>
      </DivModalBody>
    </ModalCustom>
  );
};

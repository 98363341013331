export const ACTION_LANGUAGE = "language";
export const ACTION_CURRENCY = "Currencies";
export const ACTION_LOGOUT = "logout";
export const ACTION_SIGIN = "signin";
export const ACTION_MY_ACCOUNT = "myAccount";
export const ACTION_TRAVEL_PROFILE = "travelProfile";
export const ACTION_MY_TRIPS = "myTrips";
export const ACTION_BOOKING_HISTORY = "bookingHistory";
export const ACTION_BOOKING_OTHER_ORG = "bookingOtherOrg";

export const COOKIE_BOOKING_OTHER_ORG = "org_id_initbook";
export const COOKIE_ORG_ID = "orgid"; // this is the org_id cookie original from the site

export const manuItems: MenuType = {
  sections: [
    {
      name: "",
      onlySigned: true,
      items: [
        {
          onlySigned: true,
          label: "BOOKING HISTORY",
          url: "/user-details",
          images: {
            on: "/images/userLogged/BookingHistory.svg",
            off: "/images/userLogged/BookingHistory.svg",
          },
          action: ACTION_BOOKING_HISTORY,
          id: "bookingHistory",
        },
        {
          onlySigned: true,
          label: "MY ACCOUNT",
          url: "/user-details",
          images: {
            on: "/images/userLogged/Profile.svg",
            off: "/images/userLogged/Profile.svg",
          },
          action: ACTION_MY_ACCOUNT,
          id: "profile",
        },
        {
          onlySigned: true,
          label: "TRAVEL PROFILE",
          url: "/user-details",
          images: {
            on: "/images/userLogged/Profile.svg",
            off: "/images/userLogged/Profile.svg",
          },
          action: ACTION_TRAVEL_PROFILE,
          id: "travelProfile",
        },
        {
          onlySigned: true,
          label: "MY TRIPS",
          url: "/user-details",
          images: {
            on: "/images/userLogged/Profile.svg",
            off: "/images/userLogged/Profile.svg",
          },
          action: ACTION_MY_TRIPS,
          id: "myTrips",
        },
        {
          onlySigned: true,
          label: "WALLET",
          url: "/wallet",
          images: {
            on: "/images/userLogged/WalletC.svg",
            off: "/images/userLogged/WalletC.svg",
          },
        },
      ],
    },
    {
      name: "",
      items: [
        {
          label: "Language",
          action: ACTION_LANGUAGE,
        },
        {
          label: "Currencies",
          action: ACTION_CURRENCY,
        },
      ],
    },
    {
      name: "",
      items: [
        {
          label: "LOG-OUT",
          action: ACTION_LOGOUT,
          onlySigned: true,
          images: {
            on: "/images/userLogged/LogOut.svg",
            off: "/images/userLogged/LogOut.svg",
          },
        },
        {
          label: "SIGN-IN",
          action: ACTION_SIGIN,
          onlySignout: true,
          images: {
            on: "/images/header/SignUp2.svg",
            off: "/images/header/SignUp2.svg",
          },
        },
      ],
    },
  ],
};
